@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
h1 {
    @apply text-white text-8xl font-['Arial'] font-bold uppercase
}

h6 {
    @apply font-['Gluten'] text-6xl md:text-8xl 2xl:text-9xl font-bold uppercase text-[#004D9D];
}


@media screen and (max-width: 640px) {
    h1 {
        @apply text-3xl
    }
    
} 

@media screen and (max-width: 768px) {
    h1 {
        @apply text-4xl
    }
    
} 